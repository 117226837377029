import * as ackeeTracker from "ackee-tracker"
import "./src/styles/global.css"
import DefaultLayout from "./src/components/wrapPageElement"

export const wrapPageElement = DefaultLayout

ackeeTracker
  .create("https://bunseki.cezer.xyz", {
    detailed: true,
  })
  .record("a8fd1e6d-782a-44b4-bcc6-f62b727bd798")
