import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import React from "react"

function $$(el: any) {
  return document.querySelector(el)
}

const Header = ({ siteTitle }: { siteTitle: string }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false)
  const [scrolled, setScroll] = React.useState<boolean>(false)
  const toggleNav = () => setOpen(!isOpen)
  const path = globalHistory.location.pathname

  return (
    <nav
      className={`bg-white dark:bg-black dark:text-white relative transition-colors ease-linear w-full z-50`}
    >
      <div className="px-4 sm:px-8 relative grid grid-cols-2 lg:grid-cols-[0.45fr_0.75fr_0.35fr] gap-4 pt-4 sm:pt-6 items-start">
        <div className="flex-shrink-0 flex mr-auto">
          <Link to="/" className="mb-auto">
            <p className="font-medium sm:text-xl dark:text-gray-300">
              Chris Eliezer
            </p>
          </Link>
        </div>
        <div className="flex justify-end lg:justify-start text-right lg:text-left">
          <p className="sm:text-xl font-medium text-gray-500 dark:text-gray-400 max-w-[20ch]">
            Currently based in<br/>Porto, PT
          </p>
        </div>
        <div className="col-span-2 sm:col-span-1 nav__items pt-0 sm:pt-24 lg:pt-0 flex flex-col sm:flex-row text-right sm:text-left lg:text-right sm:ml-0 lg:ml-auto text-gray-600 dark:text-gray-200 space-y-4 sm:space-y-0 sm:space-x-4">
          <Link to="/" className={`nav__link`} activeClassName="active">
            Info
          </Link>
          <Link to="/archive" className={`nav__link`} activeClassName="active">
            Archive
          </Link>
          <span className={`nav__link`}>
            Experiments
          </span>
          {/* <Link to="/about" className={`nav__link`} activeClassName="">
              About
            </Link> */}
        </div>
      </div>
    </nav>
  )
}

export default Header
