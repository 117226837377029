import { PageProps, graphql, useStaticQuery } from "gatsby"
import React from "react"
import Header from "./header"

const Layout = ({ children }: PageProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Hi!`} />

      <main className="bg-white dark:bg-black dark:text-white z-10 relative">
        {children}
      </main>

      <footer
        className="px-4 sm:px-8 pt-16 sm:pt-[32vh] pb-4 sm:pb-8 bg-white dark:bg-black grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[0.45fr_0.75fr_0.35fr] gap-4 -z-0"
        id="contact"
      >
        <div className="">
          <h2 className="text-6xl sm:text-8xl font-bold tracking-tighter dark:text-white">
            SAY HI!
          </h2>
        </div>
        <div className="text-gray-700 dark:text-gray-200 text-lg">
          <div className="flex space-x-2">
            <span>Posts.cv</span>
            <a
              href="https://posts.cv/cezer"
              className="text-gray-500 dark:text-gray-400 hover:text-black dark:hover:text-white underline underline-offset-2"
              target="_blank"
            >
              cezer
            </a>
          </div>
          <div className="flex space-x-2">
            <span>Instagram</span>
            <a
              href="https://instagram.com/heycezer"
              className="text-gray-500 dark:text-gray-400 hover:text-black dark:hover:text-white underline underline-offset-2"
              target="_blank"
            >
              heycezer
            </a>
          </div>
          <div className="flex space-x-2">
            <span>Dribbble</span>
            <a
              href="https://dribbble.com/heycezer"
              className="text-gray-500 dark:text-gray-400 hover:text-black dark:hover:text-white underline underline-offset-2"
              target="_blank"
            >
              heycezer
            </a>
          </div>
          <div className="flex space-x-2">
            <span>Mail</span>
            <a
              href="mailto:hey@cezer.xyz"
              className="text-gray-500 dark:text-gray-400 hover:text-black dark:hover:text-white underline underline-offset-2"
              target="_blank"
            >
              hey@cezer.xyz
            </a>
          </div>
        </div>

        <div className="sm:col-span-2 sm:col-start-2 text-gray-400 dark:text-gray-400 pt-8">
          <p className="font-sig text-8xl mt-8 mb-16">Cezer</p>
          {/* <p>Nice to meet you and thanks for visiting.</p> */}
          <p className="text-lg mb-4">
            ©{new Date().getFullYear()} Eliezer Christopher. All Rights Reserved.
          </p>
          <p className="text-xs text-gray-500">This site was built with GatsbyJS, content served from Prismic, and hosted on Vercel.</p>
          <p className="text-xs text-gray-500">Magic&trade; image generated using Visual Electric, and upscaled with upscale.media. Prompt within image alt tag. Abuja, NG image was shot on an iPhone at the Lower Usuma Dam, Bwari, FCT.</p>
        </div>
      </footer>
    </>
  )
}

export default Layout
